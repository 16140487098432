<template>
  <div class="container" ref="containerRef"></div>
</template>
<script>
import * as echarts from 'echarts'
import { Empty } from 'ant-design-vue'
import { getChartInterval } from '@/utils/util'
export default {
  components: {
    AEmpty: Empty
  },
  props: {
    params: {
      type: Array,
      default: () => {}
    },
    resize: {
      type: Number,
      default: 0
    }
  },
  watch: {
    params(newVal, oldVal) {
      const isEmpty = !newVal || newVal.length === 0
      if (isEmpty) {
        this.myChart && this.myChart.dispose()
        this.myChart = null
        return
      }
      this.setOption(newVal)
    },
    resize(newVal, oldVal) {
      this.myChart && this.myChart.resize()
    }
  },
  data() {
    return {
      weekArray: [],
      mileArray: [],
      timeArray: [],
      option: {
        color: ['#05ac9c', '#088ee3'],
        title: {
          show: false,
          left: 'left',
          text: '每周智驾里程及运行时长',
          textStyle: {
            fontSize: 14
          },
          padding: [2, 0, 0, 3]
        },
        tooltip: {
          trigger: 'axis'
          // formatter: '{c}'
        },

        toolbox: {
          show: false,
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          show: false,
          data: ['周累计智驾里程(KM)', '周累计运行时长(小时)']
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'line'
            },
            axisLabel: {
              fontSize: 9
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '累计里程(KM)',
            min: 0,
            interval: 50,
            axisLabel: {
              formatter: '{value}'
            },
            nameTextStyle: {
              align: 'left'
            }
          },
          {
            type: 'value',
            name: '累计时长(小时)',
            min: 0,
            interval: 5,
            axisLabel: {
              formatter: '{value}'
            },
            nameTextStyle: {
              align: 'right'
            }
          }
        ],
        series: [
          {
            name: '当周智驾里程:',
            type: 'bar',
            tooltip: {
              // trigger: 'item',
              // formatter: '当周里程:{c}公里'
              valueFormatter: function (value) {
                return value + '公里'
              }
            },
            data: []
          },
          {
            name: '当周出车时长:',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              // trigger: 'item',
              // formatter: '当周出车时长:{c}小时'
              valueFormatter: function (value) {
                return value + '小时'
              }
            },
            data: [],
            smooth: true
          }
        ]
      }
    }
  },
  mounted() {
    this.loadChart()
  },
  methods: {
    setOption(data) {
      // const echarts = window.echarts
      // 判断图表库是否加载完成
      if (!echarts) {
        setTimeout(() => {
          this.setOption(data)
        }, 500)
        return
      }
      const weekArray = []
      const mileArray = []
      const timeArray = []
      let maxOdm = 0
      let maxTime = 0
      if (data && data.length > 0) {
        data.forEach((p) => {
          weekArray.push(p.summaryDate)
          mileArray.push((p.autoCtlOdom / 1000).toFixed(2))
          timeArray.push((p.autoCtlTime / 3600).toFixed(2))
          const odom = Math.ceil(p.autoCtlOdom / 1000)
          const time = Math.ceil(p.autoCtlTime / 3600)
          if (odom > maxOdm) {
            maxOdm = odom
          }
          if (time > maxTime) {
            maxTime = time
          }
        })
        weekArray.reverse()
        mileArray.reverse()
        timeArray.reverse()
        this.option.yAxis[0].interval = getChartInterval(maxOdm)
        this.option.yAxis[1].interval = getChartInterval(maxTime)
      }

      this.option.xAxis[0].data = weekArray
      this.option.series[0].data = mileArray
      this.option.series[1].data = timeArray

      if (!this.myChart) {
        this.myChart = echarts.init(this.$refs.containerRef)
      }
      this.option && this.myChart.setOption(this.option)
    },

    loadChart() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          console.log('containerRef', this.$refs.containerRef)
          this.myChart = echarts.init(this.$refs.containerRef)
        })
      })
    }
  },
  beforeDestroy() {
    this.myChart && this.myChart.dispose()
    this.myChart = null
  }
}
</script>

<style lang="less" scoped>
.container {
  margin: 10px;
  width: 100%;
  height: 100%;
  margin-bottom: -30px;
}
</style>
